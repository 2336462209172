@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-nav-bar-v2 {
    @include styles(map.get($options, default));

    &.background {
      @include styles(map.get($options, backgrounded));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == tabs) {
        @include buildSelectorStyles(".tabs", $p1);
      }
    } @else if $p1 and $p1 != (){
      #{$n1}: $p1;
    }
  }
}