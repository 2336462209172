@use 'sass:map';
@use 'sass:math';
@import 'variables/breakpoints';
@import 'variables/grid';
@import 'variables/spacing';
@import 'utils';

@mixin make-col($size) {
  flex: 0 0 percentage(math.div($size, $grid-columns));
  max-width: percentage(math.div($size, $grid-columns));
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: math.div($x-gutter, -2);
  margin-left: math.div($x-gutter, -2);
  > * {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: math.div($x-gutter, 2);
    padding-left: math.div($x-gutter, 2);
  }
}

%col-base {
  position: relative;
  width: 100%;
  padding-right: math.div($x-gutter, 2);
  padding-left: math.div($x-gutter, 2);
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  .col#{$infix}, .col#{$infix}-auto {
    @extend %col-base;
  }
  @if $grid-columns > 0 {
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @extend %col-base;
      }
    }
  }
  @include breakpoint-media-min($breakpoint) {
    .col#{$infix} {
      flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
    }
    .col#{$infix}-auto {
      @include make-col-auto();
    }
    @if $grid-columns > 0 {
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @include make-col($i);
        }
      }
    }
  }
}

