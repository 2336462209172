@use "sass:map";
@use "sass:list";
@use "theming/theming";
@import "variables/border";
@import "variables/breakpoints";
@import "utils";

@mixin color($theme) {
  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include breakpoint-media-min($breakpoint) {
      @each $side in $border-sides {
        .bordered#{$infix}#{make-side($side)} {
          border#{make-side($side)}: list.nth($border-width, 1)
            solid
            theming.get-color($theme, list.nth($border-colors, 1));
        }

        @each $width in $border-width {
          @each $color in $border-colors {
            .bordered#{$infix}#{make-side($side)}-#{list.index($border-width, $width)}-#{$color} {
              border#{make-side($side)}: $width solid theming.get-color($theme, $color);
            }
          }
        }

        .bordered#{$infix}#{make-side($side)}-none {
          border#{make-side($side)}: none;
        }
      }
    }
  }
}
