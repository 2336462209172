$lists: (
  ul: (
    '': (
      list-style-image: url('/images/check.svg'),
      li: (
        padding-left: 12px,
      )
    ),
    default: (
      list-style-image: none,
      padding-inline-start: 18px,
      li: (
        padding-left: 0px,
      )
    ),
    base-weak: (
      list-style-image: url('/images/check_white.svg'),
      li: (
        padding-left: 12px,
      )
    )
  ),
)
