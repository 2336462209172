@use "sass:map";
@import 'variables/breakpoints';
@import "variables/fonts";
@import "variables/buttons";
@import "utils";

button {
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: $normal;
  transition: $button-transition;
  @extend .rounded-2;
  @extend .px-4;

  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include breakpoint-media-min($breakpoint) {

      @each $name,
      $params in $button-sizes {
        &.#{$name}#{$infix} {

          @each $param,
          $value in $params {
            #{$param}: $value;
          }
        }
      }
    }
  }

  &:not(:disabled),
  :not(.disabled) {
    cursor: pointer;
  }

  &.shine {
    position: relative;
    height: 56px;
    border: 0;
    transition: .6s;
    overflow: hidden;

    &:focus {
      outline: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 1);
      width: 60px;
      height: 100%;
      left: 0;
      top: 0;
      opacity: .2;
      filter: blur(30px);
      transform: translateX(-100px) skewX(-30deg);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      width: 30px;
      height: 100%;
      left: 30px;
      top: 0;
      opacity: 0;
      filter: blur(10px);
      transform: translateX(-100px) skewX(-30deg);
    }

    &:hover {
      cursor: pointer;

      &:before {
        transform: translateX(300px) skewX(-30deg);
        opacity: .8;
        transition: .7s;
      }

      &:after {
        transform: translateX(300px) skewX(-30deg);
        opacity: 1;
        transition: .7s;
      }
    }
  }
}
