@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($options: (), $selector: "wb-ui-dropdown-v2") {
  #{$selector} {
    @each $n1, $p1 in $options {
      @if type-of($p1) != "map" {
        #{$n1}: #{$p1};
      }
    }

    .dropdown {
      height: 100%;
      overflow: auto;

      &:not(.empty) {
        @include buildStyles(map.get($options, dropdown, default));
      }
    }

    .dropdown-item {
      @include buildStyles(map.get($options, item, default));

      &.active {
        @include buildStyles(map.get($options, item, active));
      }

      &:hover:not(:disabled, .disabled) {
        @include buildStyles(map.get($options, item, hover));
      }

      &.focused,
      &:focus {
        @include buildStyles(map.get($options, item, focus));
      }
    }

    .dropdown-item-label {
      @include buildStyles(map.get($options, item-label));
    }
  }
}

@mixin buildStyles($styles) {
  @each $p, $v in $styles {
    @if type-of($v) == "map" {
      @include buildSelectorStyles($p, $v);
    } @else {
      #{$p}: $v;
    }
  }
}