@use "sass:map";
@use "sass:list";
@use "theming/theming";
@import "variables/border";
@import "variables/breakpoints";
@import "utils";

@mixin rounding-mixin($side, $radius) {
  @if $side == -top {
    @include rounding-mixin(-top-left, $radius);
    @include rounding-mixin(-top-right, $radius);
  } @else if $side == -bottom {
    @include rounding-mixin(-bottom-left, $radius);
    @include rounding-mixin(-bottom-right, $radius);
  } @else if $side == -left {
    @include rounding-mixin(-top-left, $radius);
    @include rounding-mixin(-bottom-left, $radius);
  } @else if $side == -right {
    @include rounding-mixin(-top-right, $radius);
    @include rounding-mixin(-bottom-right, $radius);
  } @else {
    border#{$side}-radius: $radius;
  }
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @include breakpoint-media-min($breakpoint) {
    @each $corner in $corners {
      @each $side in $corner-sides {
        $side-made: make-side($side);
        .rounded#{$infix}#{$side-made}-#{list.index($corners, $corner)} {
          @include rounding-mixin($side-made, $corner);
        }
      }
    }
  }
}
