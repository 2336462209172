@import "../../../../../assets/styles/variables/colors";

// TODO: после перехода на новые цвета в ките, использовать тут переменные
@mixin color($palette) {
  wb-ui-toast-notification {
    .toast-notification {
      border: 1px solid;
      border-radius: 8px;

      &.message-success {
        background: #E9F9EF;
        border-color: #B6E0CE;
        color:#007A52; 
      }
      &.message-warning {
        background: #FFF7CA;
        border-color: #FBE46B;
        color:#764314; 
      }
      &.message-error {
        background: #FFEEED;
        border-color: #EC95A2;
        color:#D82E2E; 
      }
      &.message-default {
        background: #F0F5FF;
        border-color: #C2D1F0;
        color:#002D6E; 
      }
    }
  }
}
