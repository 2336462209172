$button-colors: (
  standart: (
    bg: accents-primary-500,
    color: text-primary-800,
    border: 0,
  ),
  outline: (
    bg: background-primary,
    color: text-primary-800,
    border: (
      width: 2px,
      style: solid,
      color: accents-primary-500,
    ),
  ),
  shine: (
    bg: accents-primary-500,
    color: text-primary-800,
    border: 0,
  ),
  secondary: (
    bg: background-primary,
    color: text-secondary-500,
    border: 0,
  ),
  complement: (
    bg: responsive complement-strong,
    color: base-strong,
    border: 0,
  ),
  linked: (
    bg: responsive linked-button,
    color: accent-strong,
    border: 0,
  ),
  esia: (
    bg: responsive esia,
    color: text-primary-100,
    border: 0,
  ),
  sber: (
    bg: responsive sber,
    color: base-weak,
    border: 0,
  ),
  tinkoff: (
    bg: responsive tinkoff,
    color: base-strong,
    border: 0,
  ),
  disabledStandart: (
    bg: accents-primary-200,
    color: text-secondary-300,
    border: 0,
  ),
  disabledOutline: (
    bg: background-tertiary,
    color: text-secondary-400,
    border: (
      width: 2px,
      style: solid,
      color: text-secondary-300,
    ),
  ),
  segmented: (
    bg: responsive segmented,
    color: base-strong,
    border: (
      width: 2px,
      style: solid,
      color: gray-weak,
    ),
  ),
  standart-light: (
    bg: accents-primary-300,
    color: text-primary-800,
    border: 0,
  ),
  light: (
    bg: text-primary-300,
    color: text-primary-800,
    border: 0,
  ),
);
$button-sizes: (
  large: (
    height: 56px,
    font-size: 16px,
    line-height: 20px,
  ),
  medium: (
    height: 56px,
    font-size: 16px,
    line-height: 20px,
  ),
  small: (
    height: 56px,
    font-size: 14px,
    line-height: 19px,
  ),
  xsmall: (
    height: 56px,
    font-size: 12px,
    line-height: 18px,
  ),
);

$button-transition: all 200ms linear;
