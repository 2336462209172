@use "sass:list";
@use "sass:map";
@use "sass:string";
@use "theming/theming";
@import "variables/breakpoints";
@import "variables/colors";

@function breakpoint-infix($breakpoint) {
  @return if(map.get($breakpoints, $breakpoint) == 0, "", "-#{$breakpoint}");
}

@function make-side($side) {
  @return if($side == "", "", "-#{$side}");
}

@mixin breakpoint-media-min($breakpoint) {
  $width: map.get($breakpoints, $breakpoint);
  @if $width != 0 {
    @media (min-width: $width) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin breakpoint-media-max($breakpoint) {
  $width: map.get($breakpoints, $breakpoint);
  @if $width != 0 {
    @media (max-width: $width) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin make-responsive-bg($palette, $color) {
  $config: map.get($bg-colors, $color...);

  @if type-of($config) == "map" {
    background-color: theming.get-color($palette, map.get($config, main)...);
    &:hover {
      background-color: theming.get-color($palette, map.get($config, hover)...);
    }
    &:active,
    &.active {
      background-color: theming.get-color($palette, map.get($config, active)...);
    }
  } @else {
    background-color: theming.get-color($palette, $color...);
    &:hover,
    &:active,
    &.active {
      background-color: theming.get-color($palette, $color...);
    }
  }
}

@mixin make-responsive-color($palette, $color) {
  $config: map.get($text-colors, $color...);

  @if type-of($config) == "map" {
    color: theming.get-color($palette, map.get($config, main)...);
    &:hover {
      color: theming.get-color($palette, map.get($config, hover)...);
    }
    &:active,
    &.active {
      color: theming.get-color($palette, map.get($config, active)...);
    }
  } @else {
    color: theming.get-color($palette, $color...);
    &:hover,
    &:active,
    &.active {
      color: theming.get-color($palette, $color...);
    }
  }
}

@mixin make-border($palette, $border-config) {
  @if type-of($border-config) == "map" {
    border-width: map.get($border-config, width) or 1px;
    border-style: map.get($border-config, style) or solid;
    border-color: theming.get-color($palette, map.get($border-config, color));
  } @else if type-of($border-config) == "string" {
    border-color: theming.get-color($palette, $border-config);
  } @else {
    border: $border-config;
  }
}

@mixin buildSelectorStyles($selector, $styles) {
  #{string.unquote($selector)} {
    @each $p, $v in $styles {
      @if type-of($v) == "map" {
        @include buildSelectorStyles($p, $v);
      } @else {
        #{"" + $p}: $v;
      }
    }
  }
}

@mixin buildElements($config) {
  @each $selector, $styles in $config {
    @include buildSelectorStyles($selector, $styles);
  }
}