@use 'sass:map';
@use 'sass:math';
@import 'variables/breakpoints';
@import 'utils';

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @for $i from 0 through 20 {
      .w#{$infix}-#{$i*5} {
        width: percentage(math.div($i * 5, 100));
      }
      .mw#{$infix}-#{$i*5} {
        max-width: percentage(math.div($i * 5, 100));
      }
      .h#{$infix}-#{$i*5} {
        height: percentage(math.div($i * 5, 100));
      }
      .mh#{$infix}-#{$i*5} {
        max-height: percentage(math.div($i * 5, 100));
      }
    }

    .w#{$infix}-auto {
      width: auto;
    }
    .h#{$infix}-auto {
      height: auto;
    }
  }
}
