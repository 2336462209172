@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";
@import "../../../../../../assets/styles/variables/prefixes";

@mixin theme($breakpoints ,$options: ()) {
  wb-ui-nav-item-v2 {
    @include styles($breakpoints, map.get($options, default));

    &.background {
      @include styles($breakpoints, map.get($options, backgrounded));
    }
  }
}

@mixin styles($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == item) {
        .nav-item {
          @include buildItem($breakpoints, $p1);
        }
      }
    } @else if $p1 and $p1 != (){
      #{$n1}: $p1;
    }
  }
}

@mixin buildItem($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if map.get($breakpoints, $n1) {
        @include buildResponsive($breakpoints, $n1, $p1)
      } @else if($n1 == active) {
        &.active {
          @each $n2, $p2 in $p1 {
            @if type-of($p2) == "map" {
              @if map.get($breakpoints, $n2) {
                @include buildResponsive($breakpoints, $n2, $p2)
              }
            } @else {
              #{$n2}: $p2;
            }
          }
        }
       }@else if($n1 == hover) {
        &:hover {
          @each $n2, $p2 in $p1 {
            #{$n2}: $p2;
          }
        }
      }
    } @else if $p1 and $p1 != () {
      #{$n1}: $p1;
    }
  }
}

@mixin buildResponsive($breakpoints, $breakpoint, $list) {
  @each $n1, $p1 in $list {
    @if($n1 == max) {
      @include breakpoint-media-max($breakpoint) {
        @each $n2, $p2 in $p1 {
          #{$n2}: $p2;
        }
      }
    } @else if($n1 == min) {
      @include breakpoint-media-min($breakpoint) {
        @each $n2, $p2 in $p1 {
          #{$n2}: $p2;
        }
      }
    }
  }
}