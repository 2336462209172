@use "sass:map";
@use "theming/theming";
@import "variables/buttons";
@import "utils";

@mixin color($palette) {
  button {
    @each $name, $colors in $button-colors {
      &.#{$name} {
        @include make-responsive-bg($palette, map.get($colors, bg));
        @include make-responsive-color($palette, map.get($colors, color));
        @include make-border($palette, map.get($colors, border));
      }
    }

    &.standart {
      &[disabled] {
        @include make-responsive-bg($palette, map.get($button-colors, disabledStandart, bg));
        @include make-responsive-color($palette, map.get($button-colors, disabledStandart, color));
        @include make-border($palette, map.get($button-colors, disabledStandart, border));
      }
    }

    &.outline {
      &[disabled] {
        @include make-responsive-bg($palette, map.get($button-colors, disabledOutline, bg));
        @include make-responsive-color($palette, map.get($button-colors, disabledOutline, color));
        @include make-border($palette, map.get($button-colors, disabledOutline, border));
      }
    }
  }
}
