@import 'spacing';

$xs: 375px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$breakpoints: (
  xs: 0,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

$mobile: $md;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 976px + 2 * $x-gutter,
  xl: 976px + 2 * $x-gutter,
  xxl: 1320px + 2 * $x-gutter,
);

:root {
  --default: 0;
  --xs: #{$xs};
  --sm: #{$sm};
  --md: #{$md};
  --lg: #{$lg};
  --xl: #{$xl};
}
