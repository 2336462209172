@import "@angular/cdk/overlay-prebuilt.css";

@import "theming/all-theme-v2";
@import "theming/theme";

@import "variables/badge";
@import "variables/border";
@import "variables/breakpoints";
@import "variables/buttons";
@import "variables/colors";
@import "variables/display";
@import "variables/flex";
@import "variables/fonts";
@import "variables/general";
@import "variables/grid";
@import "variables/image";
@import "variables/modal";
@import "variables/overflow";
@import "variables/positions";
@import "variables/shadows";
@import "variables/spacing";
@import "variables/typography";

@import "containers";
@import "grid";

@import "badge";
@import "border";
@import "buttons";
@import "cards";
@import "display";
@import "flex";
@import "fonts";
@import "general";
@import "images";
@import "lists";
@import "mock";
@import "modal";
@import "opacity";
@import "overflow";
@import "positions";
@import "sizing";
@import "spacing";
@import "typography";
@import "visibility";

@include all-component-theme($default-palette);
