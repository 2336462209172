@use "sass:map";
@import "../../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-code-input {
    @include styles(map.get($options, default));

    &.ng-touched.ng-invalid {
      @include styles(map.get($options, invalid));
    }

    &.animated:not(:disabled, .disabled) {
      @include styles(map.get($options, animated));
    }

    &.disabled,
    &[disabled] {
      @include styles(map.get($options, disabled));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == wrapper) {
        @include buildSelectorStyles(".code-wrapper", $p1)
      } @else if($n1 == error) {
        @include buildSelectorStyles(".error", $p1)
      } @else if($n1 == item) {
        .code {
          @include buildCodeItem($p1)
        }
      } @else {
        @include buildSelectorStyles($n1, $p1)
      }
    } @else {
      .wrapper {
        #{$n1}: #{$p1};
      }
    }
  }
}

@mixin buildCodeItem($list) {
  @each $n1, $p1 in $list {
    @if($n1 == focus) {
      &.focused {
        @include buildCodeItemContent($p1)
      }
    } @else if($n1 == filled) {
      &.filled {
        @include buildCodeItemContent($p1)
      }
    } @else if($n1 == hidden) {
      &.hidden {
        @include buildCodeItemContent($p1)
      }
    } @else if($n1 == finished) {
      &.finished {
        @include buildCodeItemContent($p1)
      }
    } @else {
      @include buildCodeItemContent($p1)
    }
  }
}

@mixin buildCodeItemContent($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == value) {
        .code-value {
          @each $n2, $p2 in $p1 {
            #{$n2}: #{$p2};
          }
        }
      }@else if($n1 == caret) {
        .caret {
          @each $n2, $p2 in $p1 {
            #{$n2}: #{$p2};
          }
        }
      }
    } @else {
      #{$n1}: #{$p1};
    }
  }
}