@use "sass:list";
@import "spacing";

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);

$modal-backdrop-bg: #000;
$modal-backdrop-opacity: 0.5;

$modal-positions: (
  center: (
    align-items: center,
    justify-content: center,
  ),
  top: (
    align-items: flex-start,
  ),
  bottom: (
    align-items: flex-end,
  ),
  left: (
    justify-content: flex-start,
  ),
  right: (
    justify-content: flex-end,
  ),
);

$modal-sizes: (
  sm: (
    width: 100%,
    max-width: 320px,
    height: unset,
    padding: 24px 14px,
  ),
  md: (
    width: 100%,
    max-width: 450px,
    height: unset,
    padding: 48px,
  ),
  lg: (
    width: 100%,
    max-width: 602px,
    height: unset,
    padding: 48px,
  ),
  fit: (
    width: unset,
    height: unset,
    max-width: unset,
  ),
  fill-width: (
    width: 100%,
  ),
  fill-height: (
    height: 100%,
  ),
  under-header: (
    max-height: calc(100% - 66px),
  ),
);

.cdk-overlay-container { position: absolute; z-index: $zindex-dropdown; }
