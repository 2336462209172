@use 'sass:map';
@import 'variables/breakpoints';
@import 'variables/overflow';
@import 'utils';

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @each $style in $overflow-styles {
      @each $side in $overflow-sides {
        .overflow#{$side}#{$infix}-#{$style} {
          overflow#{$side}: $style;
        }
      }
    }
  }
}
