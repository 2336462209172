@use 'sass:map';
@import 'variables/breakpoints';
@import 'variables/flex';
@import 'utils';

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @each $direction in $directions {
      .flex#{$infix}-#{$direction} {
        flex-direction: $direction;
      }
    }
    @for $i from 0 through 10 {
      .flex#{$infix}-grow-#{$i} {
        flex-grow: $i;
      }
    }
    @each $wrap in $wraps {
      .flex#{$infix}-#{$wrap} {
        flex-wrap: $wrap;
      }
    }
    @each $content-justify, $style in $content-justifies {
      .justify-content#{$infix}-#{$content-justify} {
        justify-content: $style;
      }
    }
    @each $item-align, $style in $item-alignments {
      .align-items#{$infix}-#{$item-align} {
        align-items: $style;
      }
    }
    @each $content-align, $style in $content-alignments {
      .align-content#{$infix}-#{$content-align} {
        align-content: $style;
      }
    }
    @each $align in $self-alignments {
      .align-self#{$infix}-#{$align} {
        align-self: $align;
      }
    }
    @for $i from 0 through $max-order {
      .order#{$infix}-#{$i} {
        order: $i;
      }
    }
    @for $i from 0 through 1 {
      .flex-grow#{$infix}-#{$i} {
        flex-grow: $i;
      }
    }
  }
}
