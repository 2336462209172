$image-sizes: (
  bank-logo: (
    width: 56px,
    height: 56px,
    mobile: (
      width: 48px,
      height: 48px,
    ),
  )
)
