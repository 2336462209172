@use 'sass:list';

@import 'variables/lists';
@import 'variables/spacing';

@mixin make-list-style($styling) {
  @each $name, $value in $styling {
    @if type-of($value) == 'map' {
      #{$name} {
        @include make-list-style($value);
      }
    } @else {
      #{$name}: $value;
    }
  }
}

@each $list, $types in $lists {
  #{$list} {
    @each $type, $styling in $types {
      @if $type == '' {
        @include make-list-style($styling);
      } @else {
        &.#{$type} {
          @include make-list-style($styling);
        }
      }
    }
  }
}



