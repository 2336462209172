@use 'sass:map';
@import 'variables/image';
@import 'variables/breakpoints';

@mixin make-style($style) {
  @each $prop, $value in $style {
    @if ($prop == mobile) {
      @media (max-width: $mobile) {
        @include make-style($value);
      }
    } @else {
      #{$prop}: $value;
    }
  }
}

@each $class, $size in $image-sizes {
  .#{$class} {
    @include make-style($size);
  }
}

.img-fluid {
  max-width: 100%;
}
