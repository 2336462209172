@use "sass:map";
@import "variables/breakpoints";
@import "variables/general";
@import "utils";

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: $fontRoboto, "sans-serif";
  font-weight: $fontRobotoWeightNormal;
}

a {
  text-decoration: none;
  outline: none;
}

.dashed-link {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-style: dashed;
}

.hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.pointer-events-none {
  pointer-events: none;
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @each $style in $float-styles {
      .float#{$infix}-#{$style} {
        float: $style;
      }
    }
    @each $style in $user-select-styles {
      .user-select#{$infix}-#{$style} {
        user-select: $style;
      }
    }
  }
}

@each $align in $vertical-alignments {
  .align-#{$align} {
    vertical-align: $align;
  }
}

@each $cursor in $cursor-styles {
  .cursor-#{$cursor} {
    cursor: $cursor;
  }
}

ol {
  padding-inline-start: 19px;
  &.multi-indexed {
    counter-reset: item;
    & ol {
      padding-inline-start: 35px;
      counter-reset: item;
    }
    & li {
      display: block;
      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        margin-left: -19px;
      }
      & ol li:before {
        margin-left: -35px;
      }
    }
  }
}
