@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";
@import "../../../../../../assets/styles/variables/prefixes";

@mixin theme($options: ()) {
  wb-ui-slider-v2 {
    @include styles(map.get($options, default));

    &.disabled {
      @include styles(map.get($options, disabled));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if ($n1 == input) {
        @include buildInput($p1);
      } @else if($n1 == track) {
        @include buildSelectorStyles(".track", $p1);
      }@else if($n1 == line) {
        @include buildSelectorStyles(".line", $p1);
      }
    } @else {
      #{$n1}: $p1;
    }
  }
}

@mixin buildInput($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if ($n1 == thumb) {
        @each $thumb-prefix in $thumb-prefixes {
          input[type="range"] {
            &::#{$thumb-prefix}-thumb {
              @each $n2, $p2 in $p1 {
                #{$n2}: $p2;
              }
            }
          }
        }
      }
    } @else {
      input[type="range"] {
        #{$n1}: $p1;
      }
    }
  }
}