@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-radio-v2 {
    @include styles(map.get($options, default));

    &.hovered:not(:disabled, .disabled, .filled) {
      @include styles(map.get($options, hover));
    }

    &.filled {
      @include styles(map.get($options, filled));
    }

    &.ng-valid {
      @include styles(map.get($options, valid));
    }

    &.ng-touched.ng-invalid {
      @include styles(map.get($options, invalid));
    }

    &.disabled,
    &[disabled] {
      @include styles(map.get($options, disabled));
    }

    &.disabled.filled,
    &[disabled].filled {
      @include styles(map.get($options, disabled-filled));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == radio) {
        @include buildSelectorStyles(".radio-mark", $p1)
      } @else if($n1 == after) {
        @include buildSelectorStyles(".radio-mark::after", $p1)
      }@else if($n1 == label) {
        @include buildSelectorStyles("label", $p1)
      }
    } @else {
      #{$n1}: $p1;
    }
  }
}