@use "sass:map";
@use "../../../../../dropdown" as dropdown;
@import "../../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-validation-input-v2 {
    wb-ui-validation-error {
        display: block;
        margin-left: map.get($options, default, input, padding-left)
    }
    
    @include styles(map.get($options, default));

    &:not(.large) .control:has(label:not(:empty)) {
        @include styles(map.get($options, with-inner-label));
    }

    &.placeholder {
        @include buildElements(map.get($options, with-placeholder));
    }

    &.large {
        @include styles(map.get($options, large));
    }

    &.medium {
        @include styles(map.get($options, medium));
    }

    &.hovered:not(:disabled, .disabled, .focused, .filled) {
      @include styles(map.get($options, hover));
    }

    &.filled {
      @include styles(map.get($options, filled));
    }

    &.focused {
      @include styles(map.get($options, focus));
    }

    &.ng-valid:not(.focused, .hovered) {
      @include styles(map.get($options, valid));
    }

    &.ng-touched.ng-invalid:not(.focused) {
      @include styles(map.get($options, invalid));
    }

    &.disabled,
    &[disabled] {
      @include styles(map.get($options, disabled));
    }
  }

  @if map.get($options, dropdown) {
    @include dropdown.theme(map.get($options, dropdown));
  }
}

@mixin styles($list) {
    @each $n1, $p1 in $list {
      @if type-of($p1) == "map" {
        @if($n1 == control) {
          @include buildSelectorStyles(".control", $p1)
        } @else if($n1 == suffix) {
          @include buildSelectorStyles(".input-suffix", $p1)
        } @else if($n1 == prefix) {
          @include buildSelectorStyles(".input-prefix", $p1)
        } @else{
          @include buildSelectorStyles($n1, $p1)
        }
      } @else {
        .wrapper {
          #{$n1}: #{$p1};
        }
      }
    }
  }