@import "./node_modules/@web-bankir/ui-kit/assets/styles/variables/fonts.scss";
@import "../src/static/css/sbp-banner.scss";
@import "./node_modules/@web-bankir/ui-kit/assets/styles/variables/general.scss";
@import "./node_modules/@web-bankir/ui-kit/assets/styles/variables/breakpoints.scss";

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  max-width: 100vw;
  -webkit-font-smoothing: antialiased;
  font-family: $fontRoboto;
  font-weight: $fontRobotoWeightNormal;

  @media (min-width: $lg) {
    overflow-x: initial;
  }
}
html {
  scroll-behavior: smooth;
  
  &.loading {
    body {
      height: 100%;
      width: 100%;
      position: fixed;
      overflow: hidden;
    }
  }

  .cdk-overlay-container {
    position: absolute;
  }

  &.open {
    .cdk-overlay-container-burger {
      z-index: $zindex-dropdown;
    }
  }

  .cdk-overlay-container-burger {
    position: fixed;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

#pit-chat-btn {
  width: 64px !important;
}

html.jivo-site-full-block body {
  overflow: hidden;
  max-height: 100%;
}

// Иконка чата должна быть скрыта за модальным окном
.__jivoMobileButton {
  z-index: calc($zindex-modal - 1)!important;
}

html {
  //TODO CRDI-178 Исправить поведение Overlay при условии открытого модального окна
  &.open {
    .cdk-overlay-container-modal {
      z-index: $zindex-dropdown + $zindex-modal;
    }
  }
}