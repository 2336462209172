@use 'sass:map';
@import 'variables/breakpoints';
@import 'variables/display';
@import 'utils';

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @each $style in $display-styles {
      .d#{$infix}-#{$style} {
        display: $style;
      }
    }
  }
}
