@use "sass:list";
@use "sass:map";
@import "variables/badge";
@import "variables/border";
@import "variables/spacing";
@import "variables/typography";

%badge {
  font-feature-settings: 'tnum' on, 'lnum' on;
  cursor: default;
  width: fit-content;
  padding: 2px 0 2px 0;
}

@each $style, $values in $badge-styles {
  .badge-#{$style} {
    @extend %badge;
  }
}
