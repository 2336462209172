@use 'sass:list';
@use 'sass:map';
@import 'variables/breakpoints';
@import 'variables/spacing';
@import 'utils';

@function make-space($space, $mod) {
  @return if($space == auto, auto, $space * $mod);
}

@function make-postfix($spaces, $space) {
  @return if($space == auto, auto, list.index($spaces, $space) - 1);
}

@mixin spacing-mixin($c, $side, $space, $mod: 1) {
  $type: if($c == p, padding, margin);
  @if $side == '' {
    #{$type}: make-space($space, $mod);
  } @else if $side == x {
    @include spacing-mixin($c, l, $space, $mod);
    @include spacing-mixin($c, r, $space, $mod);
  } @else if $side == y {
    @include spacing-mixin($c, t, $space, $mod);
    @include spacing-mixin($c, b, $space, $mod);
  } @else if $side == t {
    #{$type}-top: make-space($space, $mod);
  } @else if $side == b {
    #{$type}-bottom: make-space($space, $mod);
  } @else if $side == l {
    #{$type}-left: make-space($space, $mod);
  } @else if $side == r {
    #{$type}-right: make-space($space, $mod);
  }
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    .no-gutters#{$infix} {
      margin-left: 0;
      margin-right: 0;
      > [class*=col] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);
  @include breakpoint-media-min($breakpoint) {
    @each $c in p, m {
      @each $side in $sides {
        @each $space in $spaces {
          .#{$c}#{$side}#{$infix}-#{make-postfix($spaces, $space)} {
            @include spacing-mixin($c, $side, $space);
          }
        }
      }
    }
    @each $side in $sides {
      @each $space in $spaces {
        .m#{$side}#{$infix}-n#{make-postfix($spaces, $space)} {
          @include spacing-mixin(m, $side, $space, -1)
        }
      }
    }
  }
}
