@import "../../../../../assets/styles/variables/colors";

@mixin color($palette) {
  // $accent-strong: theming.get-color($palette, accent-strong);
  // $base-strong: theming.get-color($palette, base-strong);
  // $base-weak: theming.get-color($palette, base-weak);
  // $critic-strong-text: theming.get-color($palette, critic-strong-text);
  // $gray-strong-lighten: theming.get-color($palette, gray-strong-lighten);
  // $success-strong: theming.get-color($palette, success-strong);
  // $warning-strong-lighten: theming.get-color($palette, warning-strong-lighten);

  wb-ui-toast-message {
    %base-weak-color {
      color: $background-primary;

      .toast-btn,
      .toast-link {
        color: $background-primary;
      }
    }

    .toast {
      &-message {
        &.message-default {
          background: $background-primary;
          color: $text-primary-500;

          .toast-btn {
            color: $text-primary-500;
          }

          .toast-link {
            color: $text-primary-500;
          }
        }

        &.message-error {
          background: $status-error-500;
          color: $background-primary;
        }

        &.message-warning {
          background: $accents-primary-500;

          .toast-link {
            color: $background-primary;
          }
        }

        &.message-success {
          background: $status-success-500;
          @extend %base-weak-color;
        }

        &.message-info {
          background: $accents-primary-500;
          @extend %base-weak-color;
        }
      }

    }
  }
}
