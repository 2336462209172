@use 'sass:list';
@use 'sass:map';
@import 'variables/fonts';
@import 'variables/typography';
@import 'variables/breakpoints';
@import 'utils';

%h-base {
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: $fontRobotoWeightNormal;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin h-base() {
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: $fontRobotoWeightNormal;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
//
//%h-light {
//  font-weight: $light;
//}
//
//@mixin h-light {
//  font-weight: $light;
//}
//
//%h-bold {
//  font-weight: $bold;
//}
//
//@mixin h-bold {
//  font-weight: $bold;
//}

%p-base {
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: $fontRobotoWeightNormal;
}

@mixin p-base {
  font-family: $fontRoboto;
  font-style: normal;
  font-weight: $fontRobotoWeightNormal;
}

//%p-light {
//  font-weight: $light;
//}
//
//@mixin p-light {
//  font-weight: $light;
//}

//%p-semibold {
//  font-weight: $semibold;
//}
//
//@mixin p-semibold {
//  font-weight: $semibold;
//}
//
//%p-bold {
//  font-weight: $bold;
//}
//
//@mixin p-bold {
//  font-weight: $bold;
//}



.caption, .legal, .button1, .button2 {
  font-family: $fontRoboto;
  font-style: normal;
}
.caption, .legal {
  font-weight: $fontRobotoWeightNormal;
}
.button1, .button2 {
  font-weight: $normal;
}
.caption, .caption-medium {
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.legal {
  font-size: 10px;
  line-height: 14px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.button1 {
  font-size: 16px;
  line-height: 20px;
}
.button2 {
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.calc-period {
  font-size: 17px;
  line-height: 135%;
  font-weight: $normal;
  @media all and (min-width: 768px) {
    font-size: 20px;
  }
}

@each $breakpoint in map.keys($breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @include breakpoint-media-min($breakpoint) {
    @each $display in $displays {
      .d#{list.index($displays, $display)}#{$infix} {
        @include h-base;
        font-size: map.get($display, font-size);
        line-height: map.get($display, line-height);
        letter-spacing: map.get($display, letter-spacing);
      }
    }

    @each $heading in $headings {
      .h#{list.index($headings, $heading)}#{$infix}, h#{list.index($headings, $heading)} {
        font-size: map.get($heading, font-size);
        line-height: map.get($heading, line-height);
        @if map.has-key($heading, letter-spacing) {
          letter-spacing: map.get($heading, letter-spacing);
        }
        @if map.has-key($heading, mobile) {
          @media (max-width: $mobile) {
            $mobile: map.get($heading, mobile);
            font-size: map.get($mobile, font-size);
            line-height: map.get($mobile, line-height);
            @if map.has-key($mobile, letter-spacing) {
              letter-spacing: map.get($mobile, letter-spacing);
            }
          }
        }
      }
    }

    @each $paragraph in $paragraphs {
      .p#{list.index($paragraphs, $paragraph)}#{$infix} {
        @include p-base;

        font-size: map.get($paragraph, font-size);
        line-height: map.get($paragraph, line-height);

        @if map.has-key($paragraph, font-feature-settings) {
          font-feature-settings: map.get($paragraph, font-feature-settings);
        }
      }
    }

    @each $weight in map.keys($weights) {
      .fw#{$infix}-#{$weight} {
        font-weight: map.get($weights, $weight);
      }
    }

    @each $alignment in $text-alignments {
      .text#{$infix}-#{$alignment} {
        text-align: $alignment;
      }
    }
    .text#{$infix}-nowrap {
      white-space: nowrap;
    }
    .text#{$infix}-break {
      word-wrap: break-word;
    }
    .text#{$infix}-underline {
      text-decoration: underline;
    }
  }
}

