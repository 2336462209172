@import './variables/fonts';

@font-face {
  font-family: $fontRoboto;
  src:
    url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Bold.woff') format('woff');
  font-weight: $bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $fontRoboto;
  src:
    url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Regular.woff') format('woff');
  font-weight: $normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $fontRoboto;
  src:
    url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Light.woff') format('woff');
  font-weight: $light;
  font-style: normal;
  font-display: swap;
}
