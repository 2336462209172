$float-styles: left, right, none;
$user-select-styles: auto, all, none;
$vertical-alignments: baseline, top, middle, bottom, text-top, text-bottom;
$cursor-styles: none, default, pointer, move;

$zindex-sticky: 1020;
$zindex-dropdown: 1030;
$zindex-fixed: 1035;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-toast: 1090;
