.sbp-free {
  background: linear-gradient(90.52deg, #234663 27.53%, #36678d 51.43%, #214663 97.6%, #fafbfc 145.19%);
  min-height: 85px;

  .download-btn {
    background: url("../../assets/images/payment/free-sbp-background.svg") no-repeat;

    button {
      border-radius: 52px;
    }
  }
}
