@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($breakpoints, $options: ()) {
  wb-ui-vertical-tabset-v2 {
    @include styles($breakpoints, map.get($options, default));
  }
}

@mixin styles($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == tabset) {
        .tabset {
          @include buildResponsiveItem($breakpoints, $p1);
        }
      }@else if($n1 == head-item) {
        .tabset-head-item { 
          @include buildHeadItem($breakpoints, $p1);
        }
      }@else if($n1 == body) {
        .tabset-body {
          @include buildResponsiveItem($breakpoints, $p1);
        }
      }
    } @else if $p1 and $p1 != () {
      #{$n1}: $p1;
    }
  }
}

@mixin buildHeadItem($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if map.get($breakpoints, $n1) {
        @include buildResponsive($breakpoints, $n1, $p1)
      } @else if($n1 == hover) {
        &:hover {
          @each $n2, $p2 in $p1 {
            #{$n2}: $p2;
          }
        }
      } @else if($n1 == active) {
        &.active {
          @each $n2, $p2 in $p1 {
            @if type-of($p2) == "map" {
              @if map.get($breakpoints, $n2) {
                @include buildResponsive($breakpoints, $n2, $p2)
              }
            } @else {
              #{$n2}: $p2;
            }
          }
        }
      } @else if($n1 == label) {
        .label {
          @each $n2, $p2 in $p1 {
            @if type-of($p2) == "map" {
              @if map.get($breakpoints, $n2) {
                @include buildResponsive($breakpoints, $n2, $p2)
              }
            } @else {
              #{$n2}: $p2;
            }
          }
        }
      }
    } @else if $p1 and $p1 != () {
       #{$n1}: $p1;
    }
  }
}

@mixin buildResponsiveItem($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if map.get($breakpoints, $n1) {
        @include buildResponsive($breakpoints, $n1, $p1)
      }
    } @else if $p1 and $p1 != () {
      #{$n1}: $p1;
    }
  }
}

@mixin buildResponsive($breakpoints, $breakpoint, $list) {
  @each $n1, $p1 in $list {
    @if($n1 == max) {
      @include breakpoint-media-max($breakpoint) {
        @each $n2, $p2 in $p1 {
          #{$n2}: $p2;
        }
      }
    } @else if($n1 == min) {
      @include breakpoint-media-min($breakpoint) {
        @each $n2, $p2 in $p1 {
          #{$n2}: $p2;
        }
      }
    }
  }
}