$accents-primary-900: #7A5D0F;
$accents-primary-800: #93751A;
$accents-primary-700: #B79629;
$accents-primary-600: #DBB93B;
$accents-primary-500: #FFDE52;
$accents-primary-400: #FFE87E;
$accents-primary-300: #FFEF97;
$accents-primary-200: #FFF5B8;
$accents-primary-100: #FFFBDC;

$accents-secondary-900: #0E286C;
$accents-secondary-800: #183A83;
$accents-secondary-700: #2654A3;
$accents-secondary-600: #0274D4;
$accents-secondary-500: #4D92E3;
$accents-secondary-400: #41BBFA;
$accents-secondary-300: #67D2FC;
$accents-secondary-200: #9BE7FE;
$accents-secondary-100: #DCF1FD;

$status-success-900: #085E43;
$status-success-800: #0D7149;
$status-success-700: #158D50;
$status-success-600: #4DAE55;
$status-success-500: #6ACB6A;
$status-success-400: #96DF8F;
$status-success-300: #B6EFAA;
$status-success-200: #D6F9CA;
$status-success-100: #ECFCE4;

$status-error-900: #760D3D;
$status-error-800: #8F1542;
$status-error-700: #B1224A;
$status-error-600: #D4314F;
$status-error-500: #F74455;
$status-error-400: #FA7272;
$status-error-300: #FC988E;
$status-error-200: #FEC1B4;
$status-error-100: #FEF4F4;

$text-primary-900: #151B2A;
$text-primary-800: #1D2431;
$text-primary-700: #5B6A83;
$text-primary-600: #91A5C1;
$text-primary-500: #C4D5EA;
$text-primary-400: #E0EAF4;
$text-primary-300: #E0EDF8;
$text-primary-200: #ECF6FC;
$text-primary-100: #F5FAFD;

$text-secondary-900: #13183D;
$text-secondary-800: #21264A;
$text-secondary-700: #343A5C;
$text-secondary-600: #4C526E;
$text-secondary-500: #686D81;
$text-secondary-400: #999FB3;
$text-secondary-300: #BFC6D9;
$text-secondary-200: #DFE4F2;
$text-secondary-100: #EFF1F8;

$background-accent: $accents-primary-500;
$background-secondary: #F5F7FA;
$background-tertiary: #F3F7FD;
$background-primary: #FFFFFF;
$background-gradient: linear-gradient(180deg, #E0E8F0 0%, #F8F9FA 32.56%);

$esia: #0D4CD3;


$default-palette: (
  accents-primary-900: $accents-primary-900,
  accents-primary-800: $accents-primary-800,
  accents-primary-700: $accents-primary-700,
  accents-primary-600: $accents-primary-600,
  accents-primary-500: $accents-primary-500,
  accents-primary-400: $accents-primary-400,
  accents-primary-300: $accents-primary-300,
  accents-primary-200: $accents-primary-200,
  accents-primary-100: $accents-primary-100,

  accents-secondary-900: $accents-secondary-900,
  accents-secondary-800: $accents-secondary-800,
  accents-secondary-700: $accents-secondary-700,
  accents-secondary-600: $accents-secondary-600,
  accents-secondary-500: $accents-secondary-500,
  accents-secondary-400: $accents-secondary-400,
  accents-secondary-300: $accents-secondary-300,
  accents-secondary-200: $accents-secondary-200,
  accents-secondary-100: $accents-secondary-100,

  status-success-900: $status-success-900,
  status-success-800: $status-success-800,
  status-success-700: $status-success-700,
  status-success-600: $status-success-600,
  status-success-500: $status-success-500,
  status-success-400: $status-success-400,
  status-success-300: $status-success-300,
  status-success-200: $status-success-200,
  status-success-100: $status-success-100,

  status-error-900: $status-error-900,
  status-error-800: $status-error-800,
  status-error-700: $status-error-700,
  status-error-600: $status-error-600,
  status-error-500: $status-error-500,
  status-error-400: $status-error-400,
  status-error-300: $status-error-300,
  status-error-200: $status-error-200,
  status-error-100: $status-error-100,

  text-primary-900: $text-primary-900,
  text-primary-800: $text-primary-800,
  text-primary-700: $text-primary-700,
  text-primary-600: $text-primary-600,
  text-primary-500: $text-primary-500,
  text-primary-400: $text-primary-400,
  text-primary-300: $text-primary-300,
  text-primary-200: $text-primary-200,
  text-primary-100: $text-primary-100,

  text-secondary-900: $text-secondary-900,
  text-secondary-800: $text-secondary-800,
  text-secondary-700: $text-secondary-700,
  text-secondary-600: $text-secondary-600,
  text-secondary-500: $text-secondary-500,
  text-secondary-400: $text-secondary-400,
  text-secondary-300: $text-secondary-300,
  text-secondary-200: $text-secondary-200,
  text-secondary-100: $text-secondary-100,

  background-accent: $background-accent,
  background-secondary: $background-secondary,
  background-tertiary: $background-tertiary,
  background-primary: $background-primary,
  background-gradient: $background-gradient,

  esia: $esia,
);
