$corners: 0px, 4px, 5px, 10px, 12px, 15px, 24px, 32px;
$corner-sides: "", top, bottom, left, right, top-left, top-right, bottom-left, bottom-right;
$border-sides: "", top, bottom, left, right;
$border-width: 1px, 2px, 4px, 8px;

$border-colors: (
  text-secondary-100,
  text-secondary-200,
  text-secondary-300,
  text-secondary-500,
  text-secondary-600,
  text-primary-100,
  text-primary-200,
  text-primary-300,
  text-primary-400,
  text-primary-500,
  status-error-200,
  status-error-500,
  status-success-500,
  accents-secondary-500,
  accents-primary-500,
  background-tertiary
)

