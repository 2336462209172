@use "sass:list";
@use "sass:map";
@use "theming/theming";
@import "variables/badge";

@mixin color($palette) {
  @each $style, $values in $badge-styles {
    .badge-#{$style} {
      background-color: theming.get-color($palette, map.get($values, bg));
      color: theming.get-color($palette, map.get($values, color));
    }
  }
}
