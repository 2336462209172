@use "sass:map";
@use "sass:math";
@use "../../../v2/components/controls/validation-input/index" as input;
@use "../../../v2/components/controls/validation-phone/index" as phoneInput;
@use "../../../v2/components/controls/autocomplete/index" as autocomplete;
@use "../../../v2/components/controls/input-with-buttons/index" as inputWithButtons;
@use "../../../v2/components/controls/select/index" as select;
@use "../../../v2/components/dropdown/index" as dropdown;
@use "../../../v2/components/controls/radio/index" as radio;
@use "../../../v2/components/controls/switch/index" as switch;
@use "../../../v2/components/controls/checkbox/index" as checkbox;
@use "../../../v2/components/carousel/index" as carousel;
@use "../../../v2/components/controls/slider/index" as slider;
@use "../../../v2/components/tabs/src/components/tab-selector/tab-selector.theme.scss" as tab-selector;
@use "../../../v2/components/tabs/src/components/tabset/tabset.theme.scss" as tabset;
@use "../../../v2/components/tabs/src/components/vertical-tabset/vertical-tabset.theme.scss" as vertical-tabset;
@use "../../../v2/components/navbar/src/components/nav-bar/nav-bar.theme.scss" as nav-bar;
@use "../../../v2/components/navbar/src/components/nav-item/nav-item.theme.scss" as nav-item;
@use "../../../v2/components/controls/code-input/index" as code;

@import "../variables/fonts";
@import "../utils.scss";

$accents-primary-900: #7a5d0f;
$accents-primary-800: #93751a;
$accents-primary-700: #b79629;
$accents-primary-600: #dbb93b;
$accents-primary-500: #ffde52;
$accents-primary-400: #ffe87e;
$accents-primary-300: #ffef97;
$accents-primary-200: #fff5b8;
$accents-primary-100: #fffbdc;

$accents-secondary-900: #0e286c;
$accents-secondary-800: #183a83;
$accents-secondary-700: #2654a3;
$accents-secondary-600: #0274d4;
$accents-secondary-500: #4d92e3;
$accents-secondary-400: #41bbfa;
$accents-secondary-300: #67d2fc;
$accents-secondary-200: #9be7fe;
$accents-secondary-100: #dcf1fd;

$status-success-900: #085e43;
$status-success-800: #0d7149;
$status-success-700: #158d50;
$status-success-600: #4dae55;
$status-success-500: #6acb6a;
$status-success-400: #96df8f;
$status-success-300: #b6efaa;
$status-success-200: #d6f9ca;
$status-success-100: #ecfce4;

$status-error-900: #760d3d;
$status-error-800: #8f1542;
$status-error-700: #b1224a;
$status-error-600: #d4314f;
$status-error-500: #f74455;
$status-error-400: #fa7272;
$status-error-300: #fc988e;
$status-error-200: #fec1b4;
$status-error-100: #fef4f4;

$text-primary-900: #151b2a;
$text-primary-800: #1d2431;
$text-primary-700: #5b6a83;
$text-primary-600: #91a5c1;
$text-primary-500: #c4d5ea;
$text-primary-400: #e0eaf4;
$text-primary-300: #e0edf8;
$text-primary-200: #ecf6fc;
$text-primary-100: #f5fafd;

$text-secondary-900: #13183d;
$text-secondary-800: #21264a;
$text-secondary-700: #343a5c;
$text-secondary-600: #4c526e;
$text-secondary-500: #686d81;
$text-secondary-400: #999fb3;
$text-secondary-300: #bfc6d9;
$text-secondary-200: #dfe4f2;
$text-secondary-100: #eff1f8;

$background-accent: $accents-primary-500;
$background-secondary: #f5f7fa;
$background-tertiary: #f3f7fd;
$background-primary: #ffffff;
$background-gradient: linear-gradient(180deg, #e0e8f0 0%, #f8f9fa 32.56%);

$esia: #0d4cd3;

$default-palette: (
  accents-primary-900: $accents-primary-900,
  accents-primary-800: $accents-primary-800,
  accents-primary-700: $accents-primary-700,
  accents-primary-600: $accents-primary-600,
  accents-primary-500: $accents-primary-500,
  accents-primary-400: $accents-primary-400,
  accents-primary-300: $accents-primary-300,
  accents-primary-200: $accents-primary-200,
  accents-primary-100: $accents-primary-100,

  accents-secondary-900: $accents-secondary-900,
  accents-secondary-800: $accents-secondary-800,
  accents-secondary-700: $accents-secondary-700,
  accents-secondary-600: $accents-secondary-600,
  accents-secondary-500: $accents-secondary-500,
  accents-secondary-400: $accents-secondary-400,
  accents-secondary-300: $accents-secondary-300,
  accents-secondary-200: $accents-secondary-200,
  accents-secondary-100: $accents-secondary-100,

  status-success-900: $status-success-900,
  status-success-800: $status-success-800,
  status-success-700: $status-success-700,
  status-success-600: $status-success-600,
  status-success-500: $status-success-500,
  status-success-400: $status-success-400,
  status-success-300: $status-success-300,
  status-success-200: $status-success-200,
  status-success-100: $status-success-100,

  status-error-900: $status-error-900,
  status-error-800: $status-error-800,
  status-error-700: $status-error-700,
  status-error-600: $status-error-600,
  status-error-500: $status-error-500,
  status-error-400: $status-error-400,
  status-error-300: $status-error-300,
  status-error-200: $status-error-200,
  status-error-100: $status-error-100,

  text-primary-900: $text-primary-900,
  text-primary-800: $text-primary-800,
  text-primary-700: $text-primary-700,
  text-primary-600: $text-primary-600,
  text-primary-500: $text-primary-500,
  text-primary-400: $text-primary-400,
  text-primary-300: $text-primary-300,
  text-primary-200: $text-primary-200,
  text-primary-100: $text-primary-100,

  text-secondary-900: $text-secondary-900,
  text-secondary-800: $text-secondary-800,
  text-secondary-700: $text-secondary-700,
  text-secondary-600: $text-secondary-600,
  text-secondary-500: $text-secondary-500,
  text-secondary-400: $text-secondary-400,
  text-secondary-300: $text-secondary-300,
  text-secondary-200: $text-secondary-200,
  text-secondary-100: $text-secondary-100,

  background-accent: $background-accent,
  background-secondary: $background-secondary,
  background-tertiary: $background-tertiary,
  background-primary: $background-primary,
  background-gradient: $background-gradient,

  esia: $esia,
);

.ucb-form {
  position: relative;

  .input-suffix {
    position: absolute;
    top: 18px;
    bottom: 18px;
    right: 16px;
    z-index: 10;
    cursor: pointer;
    display: flex;

    & + input {
      padding-right: 56px;

      & + label {
        max-width: calc(100% - 56px);
      }
    }
  }
}

input,
textarea {
  font-family: $fontRoboto;
  font-style: normal;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 18px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 18px;
  outline: none;
  caret-color: #1355ff;
  color: map.get($default-palette, text-secondary-400);
  border-color: map.get($default-palette, text-secondary-300);
  background-color: map.get($default-palette, background-primary);
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  transition: all 0.2s linear;

  &::placeholder {
    color: map.get($default-palette, text-secondary-400);
  }

  &:has(+ label:not(:empty)) {
    padding-top: 26px;
    padding-bottom: 10px;
  }

  & + label:not(:empty) {
    position: absolute;
    top: 18px;
    left: 16px;
    right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s linear;
    font-size: 14px;
    line-height: 19px;
    color: map.get($default-palette, text-secondary-400);
  }

  &:hover {
    cursor: pointer;
    color: map.get($default-palette, text-secondary-500);
    border-color: map.get($default-palette, text-secondary-400);

    & + label {
      cursor: pointer;
      color: map.get($default-palette, text-secondary-500);
    }
  }

  &.search,
  &:focus:not([type="range"]) {
    border-color: map.get($default-palette, text-primary-800);
    color: map.get($default-palette, text-primary-800);

    & + label {
      color: map.get($default-palette, text-primary-800);
    }
  }

  &.filled:not(:focus, :disabled),
  &:-webkit-autofill {
    border-color: map.get($default-palette, text-secondary-300);
    color: map.get($default-palette, text-primary-800);

    & + label {
      color: map.get($default-palette, text-secondary-400);
    }
  }

  &.ng-touched.ng-invalid:not(.slider, :focus) {
    border-color: map.get($default-palette, status-error-500);
    color: map.get($default-palette, text-primary-800);

    & + label {
      color: map.get($default-palette, text-primary-800);
    }
  }

  &.ng-valid:not(.slider, :focus) {
    border-color: map.get($default-palette, text-secondary-300);
    color: map.get($default-palette, text-primary-800);

    & + label {
      color: map.get($default-palette, text-secondary-400);
    }
  }

  &.disabled,
  &[disabled] {
    border-color: map.get($default-palette, text-secondary-200);
    cursor: default;
    color: map.get($default-palette, text-secondary-300);

    & + label {
      color: map.get($default-palette, text-secondary-300);
    }
  }
}

input:focus + label:not(:empty),
input.with-placeholder + label:not(:empty),
textarea:focus + label:not(:empty),
input.filled + label:not(:empty),
input:-webkit-autofill + label:not(:empty),
textarea.filled + label:not(:empty),
textarea:-webkit-autofill + label:not(:empty),
input + label:not(:empty).active,
textarea + label:not(:empty).active {
  top: 8px;
  font-size: 10px;
  line-height: 13.5px;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-background-clip: text;
}

textarea {
  resize: vertical;
  min-height: 56px;
  padding-top: 9px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 9px;
}

input.search {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 11px;
  padding-bottom: 16px;

  border-radius: 12px 0 0 12px;
}

@include dropdown.theme(
  (
    z-index: 1030,
    position: relative,
    max-height: 220px,
    dropdown: (
      default: (
        border: 1px solid,
        border-color: map.get($default-palette, text-secondary-200),
        background-color: map.get($default-palette, background-primary),
        border-radius: 14px,
        "&::-webkit-scrollbar-thumb": (
          border-radius: 32px,
          background-clip: padding-box,
          background-color: map.get($default-palette, text-secondary-200),
          border: 6px solid transparent,
        ),
        "&::-webkit-scrollbar": (
          background-color: transparent,
          width: 17px,
        ),
        "&::-webkit-scrollbar-track": (
          background-color: transparent,
          margin: 5px,
        ),
      ),
    ),
    item: (
      default: (
        display: flex,
        align-items: center,
        cursor: pointer,
        padding: 12px 16px,
        color: map.get($default-palette, text-primary-800),
      ),
      hover: (
        background: map.get($default-palette, text-secondary-100),
      ),
      active: (
        background: map.get($default-palette, text-secondary-100),
      ),
      focus: (
        background: map.get($default-palette, text-secondary-100),
      ),
    ),
    item-label: (
      overflow: hidden,
      word-break: inherit,
    ),
  )
);

@include input.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      flex-direction: row,
      border-radius: 12px,
      border: 1px solid map.get($default-palette, text-secondary-300),
      background-color: map.get($default-palette, background-primary),
      transition: all 0.2s linear,
      control: (
        position: relative,
        width: 100%,
      ),
      label: (
        position: absolute,
        top: 18px,
        left: 16px,
        right: 16px,
        overflow: hidden,
        text-overflow: ellipsis,
        white-space: nowrap,
        transition: all 0.2s linear,
        font-size: 14px,
        line-height: 19px,
        pointer-events: none,
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        width: 100%,
        font-size: 14px,
        font-weight: 400,
        line-height: 18px,
        padding-top: 18px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 18px,
        outline: none,
        border-radius: 12px,
        transition: all 0.2s linear,
        caret-color: #1355ff,
        color: map.get($default-palette, text-secondary-400),
        border: none,
        background-color: transparent,
        "&:hover, &:focus": (
          border: none,
          background-color: transparent,
        ),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-400),
        ),
      ),
      prefix: (
        display: flex,
        align-items: center,
        margin-left: 16px,
        "&:empty": (
          display: none,
        ),
      ),
      suffix: (
        display: flex,
        align-items: center,
        margin-right: 16px,
        order: 2,
        "&:empty": (
          display: none,
        ),
      ),
    ),
    with-placeholder: (
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
      ),
    ),
    large: (
      input: (
        font-size: 28px,
        font-weight: 600,
        line-height: 38px,
        padding-top: 13px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 13px,
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
    ),
    with-inner-label: (
      input: (
        padding-top: 26px,
        padding-bottom: 10px,
      ),
    ),
    hover: (
      border-color: map.get($default-palette, text-secondary-400),
      label: (
        color: map.get($default-palette, text-secondary-500),
      ),
      input: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
    ),
    filled: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    focus: (
      border-color: map.get($default-palette, text-primary-800),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-primary-800),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    valid: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    invalid: (
      border-color: map.get($default-palette, status-error-500),
      label: (
        color: map.get($default-palette, text-primary-800),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    disabled: (
      border-color: map.get($default-palette, text-secondary-200),
      label: (
        color: map.get($default-palette, text-secondary-300),
      ),
      input: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
      suffix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
      prefix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
    ),
  )
);

@include autocomplete.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      flex-direction: row,
      border-radius: 12px,
      border: 1px solid map.get($default-palette, text-secondary-300),
      background-color: map.get($default-palette, background-primary),
      transition: all 0.2s linear,
      control: (
        position: relative,
        width: 100%,
      ),
      label: (
        position: absolute,
        top: 18px,
        left: 16px,
        right: 16px,
        overflow: hidden,
        text-overflow: ellipsis,
        white-space: nowrap,
        transition: all 0.2s linear,
        font-size: 14px,
        line-height: 19px,
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        width: 100%,
        font-size: 14px,
        font-weight: 400,
        line-height: 18px,
        padding-top: 18px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 18px,
        outline: none,
        border-radius: 12px,
        transition: all 0.2s linear,
        caret-color: #1355ff,
        color: map.get($default-palette, text-secondary-400),
        border: none,
        background-color: transparent,
        "&:hover, &:focus": (
          border: none,
          background-color: transparent,
        ),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-400),
        ),
      ),
      suffix: (
        display: flex,
        align-items: center,
        margin-right: 16px,
        order: 2,
      ),
      prefix: (
        display: flex,
        align-items: center,
        margin-left: 16px,
      ),
    ),
    with-inner-label: (
      input: (
        padding-top: 26px,
        padding-bottom: 10px,
      ),
    ),
    with-placeholder: (
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
      ),
    ),
    hover: (
      border-color: map.get($default-palette, text-secondary-400),
      label: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
      input: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
    ),
    filled: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    focus: (
      border-color: map.get($default-palette, text-primary-800),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-primary-800),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    valid: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        color: map.get($default-palette, text-secondary-400),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    invalid: (
      border-color: map.get($default-palette, status-error-500),
      label: (
        color: map.get($default-palette, text-primary-800),
      ),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    disabled: (
      pointer-events: none,
      border-color: map.get($default-palette, text-secondary-200),
      label: (
        color: map.get($default-palette, text-secondary-300),
      ),
      input: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
      suffix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
      prefix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
    ),
  )
);

@include select.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      min-height: 56px,
      display: flex,
      flex-direction: row,
      border-radius: 12px,
      border: 1px solid map.get($default-palette, text-secondary-300),
      background-color: map.get($default-palette, background-primary),
      transition: all 0.2s linear,
      cursor: pointer,
      control: (
        position: relative,
        width: 100%,
        font-size: 14px,
        font-weight: 400,
        line-height: 18px,
        padding-top: 18px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 18px,
        color: map.get($default-palette, text-secondary-400),
      ),
      description: (
        font-weight: 400,
        font-size: 12px,
        line-height: 135%,
      ),
      label: (
        position: absolute,
        top: 18px,
        left: 16px,
        right: 16px,
        overflow: hidden,
        text-overflow: ellipsis,
        white-space: nowrap,
        transition: all 0.2s linear,
        font-size: 14px,
        line-height: 19px,
        pointer-events: none,
        color: map.get($default-palette, text-secondary-400),
      ),
      prefix: (
        display: flex,
        align-items: center,
        margin-left: 16px,
        transition: all 0.2s linear,
      ),
      suffix: (
        display: flex,
        align-items: center,
        margin-right: 16px,
        order: 2,
      ),
    ),
    with-inner-label: (
      control: (
        padding-top: 26px,
        padding-bottom: 10px,
      ),
    ),
    hover: (
      border-color: map.get($default-palette, text-secondary-400),
      label: (
        color: map.get($default-palette, text-secondary-500),
      ),
      control: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
    ),
    filled: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-secondary-400),
      ),
      control: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    focus: (
      border-color: map.get($default-palette, text-primary-800),
      label: (
        top: 8px,
        font-size: 10px,
        line-height: 13.5px,
        color: map.get($default-palette, text-primary-800),
      ),
      control: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    valid: (
      border-color: map.get($default-palette, text-secondary-300),
      label: (
        color: map.get($default-palette, text-secondary-400),
      ),
      control: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    invalid: (
      border-color: map.get($default-palette, status-error-500),
      label: (
        color: map.get($default-palette, text-primary-800),
      ),
      control: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    disabled: (
      pointer-events: none,
      cursor: default,
      border-color: map.get($default-palette, text-secondary-200),
      label: (
        color: map.get($default-palette, text-secondary-300),
      ),
      control: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
      ),
      suffix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
      prefix: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "& > *": (
          color: inherit,
        ),
      ),
    ),
  )
);

@include inputWithButtons.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      flex-direction: row,
      border-radius: 12px,
      border: 1px solid map.get($default-palette, text-secondary-300),
      background-color: map.get($default-palette, background-primary),
      transition: all 0.2s linear,
      control: (
        width: 100%,
      ),
      input: (
        width: 100%,
        font-size: 14px,
        font-weight: 400,
        line-height: 18px,
        padding-top: 18px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 18px,
        outline: none,
        border-radius: 12px,
        transition: all 0.2s linear,
        caret-color: #1355ff,
        color: map.get($default-palette, text-secondary-400),
        border: none,
        background-color: transparent,
        "&:hover, &:focus": (
          border: none,
          background-color: transparent,
        ),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-400),
        ),
      ),
      suffix: (
        display: flex,
        align-items: center,
        margin-right: 16px,
        order: 2,
        button: (
          color: map.get($default-palette, text-primary-800),
          border-radius: 4px,
          padding-left: 16px,
          padding-right: 16px,
          font-size: 14px,
          line-height: 24px,
          font-weight: 400,
          height: calc(100% - 16px),
          background-color: map.get($default-palette, accents-primary-500),
          border: 0,
        ),
      ),
    ),
    hover: (
      border-color: map.get($default-palette, text-secondary-400),
      input: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
    ),
    focus: (
      border-color: map.get($default-palette, text-primary-800),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    filled: (
      border-color: map.get($default-palette, text-secondary-300),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    invalid: (
      border-color: map.get($default-palette, status-error-500),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    disabled: (
      border-color: map.get($default-palette, text-secondary-200),
      input: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
    ),
  )
);

@include phoneInput.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      flex-direction: row,
      border-radius: 12px,
      border: 1px solid map.get($default-palette, text-secondary-300),
      background-color: map.get($default-palette, background-primary),
      transition: all 0.2s linear,
      control: (
        position: relative,
        width: 100%,
      ),
      input: (
        width: 100%,
        font-size: 16px,
        font-weight: 400,
        line-height: 20px,
        padding-top: 17px,
        padding-left: 16px,
        padding-right: 16px,
        padding-bottom: 17px,
        outline: none,
        border-radius: 12px,
        transition: all 0.2s linear,
        caret-color: #1355ff,
        color: map.get($default-palette, text-secondary-300),
        border: none,
        background-color: transparent,
        "&:hover, &:focus": (
          border: none,
          background-color: transparent,
        ),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
      prefix: (
        display: flex,
        align-items: center,
        margin-left: 16px,
        font-size: 16px,
        font-weight: 600,
        padding-right: 16px,
        border-right: 1px solid,
        border-color: inherit,
        "&:empty": (
          display: none,
        ),
      ),
    ),
    hover: (
      border-color: map.get($default-palette, text-secondary-400),
      input: (
        cursor: pointer,
        color: map.get($default-palette, text-secondary-500),
      ),
    ),
    focus: (
      border-color: map.get($default-palette, text-primary-800),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    filled: (
      border-color: map.get($default-palette, text-secondary-300),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    invalid: (
      border-color: map.get($default-palette, status-error-500),
      input: (
        color: map.get($default-palette, text-primary-800),
      ),
    ),
    disabled: (
      pointer-events: none,
      border-color: map.get($default-palette, text-secondary-200),
      input: (
        cursor: default,
        color: map.get($default-palette, text-secondary-300),
        "&::placeholder": (
          color: map.get($default-palette, text-secondary-300),
        ),
      ),
      prefix: (
        color: map.get($default-palette, text-secondary-300),
      ),
    ),
  )
);


@include radio.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      align-items: center,
      cursor: pointer,
      label: (
        cursor: inherit,
      ),
      radio: (
        margin-right: 8px,
        position: relative,
        width: 20px,
        height: 20px,
        flex-shrink: 0,
        cursor: inherit,
        background-color: map.get($default-palette, background-primary),
        border: 1px solid map.get($default-palette, text-secondary-300),
        border-radius: 50%,
        transition: all 0.2s linear,
      ),
      after: (
        content: '',
        position: absolute,
        top: calc(50% - 5px),
        left: calc(50% - 5px),
        width: 10px,
        height: 10px,
        border-radius: 50%,
      )
    ),
    hover: (
      radio: (
        border-color: map.get($default-palette, text-secondary-300),
        background-color: map.get($default-palette, text-secondary-100),
      ),
    ),
    valid: (),
    invalid: (
      radio: (
        border-color: map.get($default-palette, status-error-500),
        background-color: map.get($default-palette, background-primary),
      ),
    ),
    filled: (
      radio: (
        border-color: map.get($default-palette, text-primary-800),
      ),
      after: (
        background-color: map.get($default-palette, accents-primary-500),
      )
    ),
    disabled: (
      cursor: not-allowed,
      radio: (
        border-color: map.get($default-palette, text-secondary-200),
      )
    ),
    disabled-filled: (
      cursor: not-allowed,
      radio: (
        border-color: map.get($default-palette, text-secondary-300),
      ),
      after: (
        background-color: map.get($default-palette, accents-primary-200),
      )
    ),
  ),
);

@include switch.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      align-items: center,
      switch: (
        position: relative,
        background-color: map.get($default-palette, text-secondary-200),
        width: 40px,
        height: 24px,
        border-radius: 12px,
        transition: all 0.2s linear,
        cursor: pointer,
      ),
      toggler: (
        position: absolute,
        left: 0,
        top: 0,
        background-color: map.get($default-palette, background-primary),
        background-repeat: no-repeat,
        background-position: center,
        background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 3L9 9M9 3L3 9L9 3Z" stroke="%23686D81" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'),
        border-radius: 50%,
        border: 2px solid map.get($default-palette, text-secondary-200),
        width: 24px,
        height: 24px,
        transition: all 0.2s linear,
      )
    ),
    filled: (
      switch: (
        background-color: map.get($default-palette, accents-primary-500),
      ),
      toggler: (
        left: calc(100% - 24px),
        border-color: map.get($default-palette, text-primary-800),
        background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 6L5 8.5L10 3.5" stroke="%231D2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'),
      )
    ),
    hover: (
      switch: (
        background-color: map.get($default-palette, text-secondary-300),
      ),
      toggler: (
        border-color: map.get($default-palette, text-secondary-300),
      )
    ),
    hover-filled: (
      switch: (
        background-color: #FFC700,
      ),
    ),
    disabled: (
      switch: (
        background-color: map.get($default-palette, text-secondary-200),
        cursor: not-allowed,
      ),
      toggler: (
        border-color: map.get($default-palette, text-secondary-200),
        background-image:url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 3L9 9M9 3L3 9L9 3Z" stroke="%23DFE4F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'),
      )
    ),
    disabled-filled: (
      cursor: not-allowed,
      switch: (
        background-color: map.get($default-palette, accents-primary-200),
      ),
      toggler: (
        border-color: map.get($default-palette, text-secondary-300),
        background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 6L5 8.5L10 3.5" stroke="%23BFC6D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'),
      )
    ),
  )
);

@include checkbox.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      display: flex,
      align-items: stretch,
      cursor: pointer,
      label: (
        cursor: inherit,
        margin-left: 8px,
        font-weight: 400,
        font-size: 14px,
        line-height: 19px,
      ),
      checkbox: (
        width: 18px,
        height: 18px,
        border: 1px solid map.get($default-palette, text-primary-800),
        background-color: map.get($default-palette, background-primary),
        border-radius: 3px,
        flex-shrink: 0,
        position: relative,
        transition: all 0.2s linear,
        cursor: inherit,
      ),
      after: (
        content: '',
        position: absolute,
        top: 0,
        left: 0,
        width: 100%,
        height: 100%,
        opacity: 0,
        background: url('data:image/svg+xml,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 4L4.5 7L7.5 4L10.5 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center center no-repeat,
      )
    ),
    filled: (
      checkbox: (
        background-color: map.get($default-palette, text-primary-800),
      ),
      after: (
        opacity: 1,
      )
    ),
    hover: (
      checkbox: (
        background-color: map.get($default-palette, text-secondary-100),
      ),
    ),
    valid: (),
    invalid: (
      checkbox: (
        border-color: map.get($default-palette, status-error-500),
        background-color: map.get($default-palette, background-primary),
      ),
    ),
    disabled: (
      cursor: not-allowed,
      checkbox: (
        border: 1px solid map.get($default-palette, text-secondary-300),
        background-color: map.get($default-palette, background-primary),
      ),
    ),
    disabled-filled: (
      cursor: not-allowed,
      checkbox: (
        border: 1px solid map.get($default-palette, text-secondary-300),
        background-color: map.get($default-palette, text-secondary-300),
      ),
      after: (
        opacity: 1,
      )
    ),
  )
);

@include carousel.theme(
  (
    default: (
      position: relative,
      display: flex,
      flex-direction: column,
      container: (
        position: relative,
        margin-left: -12px,
        margin-right: -12px,
        margin-bottom: 16px,
        padding-left: 4px,
        padding-right: 4px
      ),
      content: (
        display: flex,
        scrollbar-width: none,
        padding-top: 16px,
        padding-bottom: 16px,
        flex-wrap: nowrap,
        overflow: hidden,
        transition: transform 0.3s ease-out,
      ),
      nav: (
        position: absolute,
        bottom: 0,
        top: 0,
        display: flex,
        align-items: center,
        justify-content: center,
        padding-left: 12px,
        padding-right: 12px,
        button: (
          default: (
            width: 24px,
            height: 24px,
            border: 0,
            padding: 0,
            border-radius: 4px,
            transition: color 0s,
          ),
        ),
        left: (
          left: 0,
            gradiented: (
              background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%),
            )
        ),
        right: (
          right: 0,
            gradiented: (
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 100%),
            )
        )
      ),
      dots: (
        display: flex,
        justify-content: center,
        dot: (
          default: (
            display: inline-block,
            height: 7px,
            width: 7px,
            margin-right: 9px,
            border-radius: 50%,
            transition: background-color 0.6s ease,
            cursor: pointer,
            background-color: map.get($default-palette, text-secondary-500),
          ),
          active: (
            background-color: map.get($default-palette, accents-primary-500),
          )
        )
      )
    )
  )
);

@include slider.theme((
  default: (
    position: relative,
    top: -4px,
    left: 0px,
    right: 4px,
    display: block,
    height: 4px,
    input: (
      position: absolute,
      z-index: 2,
      top: 0px,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0,
      width: 100%,
      height: 4px,
      background: transparent,
      border-radius: 1px,
      border: 0,
      appearance: none,
      -webkit-appearance: none,
      -moz-appearance: none,
      thumb: (
        height: 18px,
        width: 18px,
        border-width: 3px,
        border-style: solid,
        border-radius: 100%,
        border-color: map.get($default-palette, accents-primary-500),
        background-color: map.get($default-palette, background-primary),
        cursor: pointer,
        -webkit-appearance: none,
      )
    ),
    track: (
      position: absolute,
      z-index: 1,
      height: 4px,
      border-radius: 100px,
      background: map.get($default-palette, accents-primary-500),
    ),
    line: (
      position: absolute,
      z-index: 0,
      top: 0,
      display: block,
      width: 100%,
      height: 4px,
      border-radius: 100px,
      background-color: map.get($default-palette, text-secondary-100),
    )
  ), 
  disabled: (
    track: (
      background: map.get($default-palette, text-secondary-100),
    ),
    input: (
      thumb: (
        border-width: 6px,
        border-style: solid,
        border-color: map.get($default-palette, background-primary)
      ),
    )
  )
));

@include tab-selector.theme((
  default: (
    tabs: (
      margin: 0 -16px,
      padding: 0 8px,
      display: flex,
      flex-wrap: nowrap,
    ),
    tab: (
      default: (
        font-family: $fontRoboto,
        font-style: normal,
        font-size: 16px,
        font-weight: 400,
        padding-left: 16px,
        padding-right: 16px,
        flex: 0 0 auto,
        width: auto,
        content: (
          cursor: pointer,
          height: 100%,
          // пока нет ui для данного класса
          title: ()
        )
      ),
      // пока нет ui для данного класса
      disabled: (),
      hover: (),
    ),
  ),
  indicator: (
    tab: (
      default: (
        content: (
          padding-top: 8px,
          padding-bottom: 8px,
          position: relative,
          color: map.get($default-palette, text-secondary-500),
          after: (
            content: '',
            position: absolute,
            bottom: -3px,
            left: 0,
            right: 0,
            height: 4px,
            border-radius: 2px,
          ),
        ),
      ),
      active: (
        content: (
          color: map.get($default-palette, text-primary-800),
          after: (
            background: map.get($default-palette, accents-primary-500),
          )
        )
      ),
    )
  ),
  // пока нет ui для данного класса
  backgrounded: (),
));

@include tabset.theme(
  $breakpoints,
  (
    default: (
      tabset: (
        font-family: $fontRoboto,
        font-style: normal,
      ),
      head:(),
      body: (
        padding-top: 12px,
        padding-bottom: 12px,
      ),
      // для дополнительной/отдельной стилизации tab-selector внутри tabset
      selector: (),
    ),
    backgrounded: (
      tabset: (),
      head:(),
      body: (
        md: (
          max: (
            padding: 16px,
            margin: 0 -16px,
            max-width: calc(100% + 32px),
            width: calc(100% + 32px),
          )
        )
      )
  )
));

//todo цветовой стилизации пока нет из-за устаревших стилей
@include vertical-tabset.theme(
  $breakpoints,
  (
    default: (
      tabset: (
        font-family: $fontRoboto,
        font-style: normal,
        display: grid,
        margin: 0,
        padding-left: 16px,
        padding-right: 16px,
        padding-top: 24px,
        padding-bottom: 24px,
        border-radius: 4px,
        overflow: hidden,
        md: (
          min: (
            grid-template-columns: 300px auto,
            grid-template-rows: repeat(2, auto),
            padding: 0,
          ),
          max: (
            grid-row-gap: 12px,          
          )
        )
      ),
      head-item: (
        padding-right: 8px,
        padding-left: 8px,
        max-width: 100%,
        text-decoration: underline,
        text-decoration-thickness: 1px,
        text-decoration-style: dashed,
        hover: (
          text-decoration-style: solid,
        ),
        active: (
          text-decoration: none,
          md: (
            max: (
              padding-bottom: 16px,
              align-items: initial,
            )
          )
        ),
        label: (
          padding-left: 4px,
          md: (
            min: (
              padding: 24px,
            )
          )
        ),
        md: (
          max: (
            border-left-width: 3px,
            border-left-style: solid,
            display: flex,
            align-items: center,
          ),
          min: (
            grid-column: 1,
            cursor: pointer,
            border-left-width: 4px,
            border-left-style: solid,
            border-right-width: 2px,
            border-right-style: solid,
            border-left-color: transparent
          )
        )
      ),
      body: (
        padding-left: 16px,
        md: (
          max: (
            border-left-width: 3px,
            border-left-style: solid
          ),
          min: (
            padding: 48px,
            grid-column: 2 / last-col
          )
        ),
      ),
    )
  )
);

@include nav-bar.theme(
  (
    default: (
      tabs: (
        font-family: $fontRoboto,
        font-style: normal,
        display: flex,
        overflow: auto,
        margin: 0 -16px,
        padding: 0 8px,
        width: calc(100% + 48px)
      )
    ),
    backgrounded: (
      tabs: (
        padding: 0 20px
      )
    )
  )
);

//todo цветовой стилизации пока нет из-за устаревших стилей
@include nav-item.theme(
  $breakpoints,
  (
    default: (
      cursor: pointer,
      padding: 0 12px,
      display: inline-flex,
      item: (
        font-family: $fontRoboto,
        font-style: normal,
        font-size: 12px,
        line-height: 135%,
        font-weight: 600,
        white-space: nowrap,
        padding-top: 8px,
        padding-bottom: 8px,
        active: (
          border-bottom-style: solid,
          border-bottom-width: 2px
        ),
        hover: (),
      )
    ),
    backgrounded: (
      padding-left: 4px,
      padding-right: 4px,
      item: (
        padding: 8px 24px,
        border-top-left-radius: 4px,
        border-top-right-radius: 4px,
        md: (
          max: (
            padding: 8px,
          )
        ),
        active: (
          border-bottom: none,
        ),
        hover: (),
      ),
    )
  )
);

@include code.theme(
  (
    default: (
      font-family: $fontRoboto,
      font-style: normal,
      width: min-content,
      wrapper: (
        width: max-content,
        display: flex,
        position: relative,
      ),
      input: (
        position: absolute,
        z-index: 2,
        opacity: 0,
        caret-color: transparent,
      ),
      label: (
        position: relative,
        top: 0,
        left: 0,
        max-width: unset,
      ),
      item: (
        default: (
          display: flex,
          justify-content: center,
          align-items: center,
          border-radius: 12px,
          border: 1px solid map.get($default-palette, text-secondary-200),
          width: 56px,
          height: 56px,
          transition: all 0.2s linear,
          value: (
            font-size: 24px,
            line-height: 135%,
            font-weight: 600,
            color: map.get($default-palette, text-primary-800),
          ),
        ),
        focus: (
          border-color: map.get($default-palette, text-primary-800),
          caret: (
            height: 24px,
            width: 1px,
            border-right: 1px solid #1355ff,
            animation: 0.9s forwards infinite caret,
          )
        ),
        filled: (
          border-color: map.get($default-palette, text-primary-800),
        ),
        hidden: (
          text-security: disc,
          -webkit-text-security: disc,
          -moz-text-security: disc,
        )
      ),
      error: (
        margin-top: 8px,
        font-weight: 400,
        font-size: 14px,
        line-height: 135%,
        color: map.get($default-palette, status-error-500),
      ),
    ),
    disabled: (
      item: (
        default: (
          border-color: map.get($default-palette, text-secondary-200),
          value: (
            color: map.get($default-palette, text-secondary-200),
          )
        )
      )
    ),
    invalid: (
      item: (
        finished: (
          border-color: map.get($default-palette, text-secondary-200),
          value: (
            color: map.get($default-palette, status-error-500),
          )
        )
      )
    ),
    animated: (
      item: (
        default: (
          border-color: map.get($default-palette, text-secondary-200),
          value: (
            color: map.get($default-palette, status-error-500),
            animation: 0.7s backwards infinite shake,
          )
        )
      )
    )
  )
);

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-1px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(1px);
  }
};
@keyframes caret {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}