@use "sass:map";
@use "sass:list";
@use "sass:math";

@import "utils";

@import "variables/general";
@import "variables/breakpoints";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  &.show {
    display: flex;
  }

  &:not(.backdrop) {
    pointer-events: none;

    .modal-dialog {
      pointer-events: auto;
    }
  }

  &-dialog {
    max-height: 100%;
    max-width: 100vw;
    padding: 48px 20px 20px;
  }

  &-content {
    height: 100%;
    padding-left: $x-gutter;
    padding-right: $x-gutter;
    margin-left: -$x-gutter;
    margin-right: -$x-gutter;
  }

  &-body {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: $x-gutter;
    padding-right: $x-gutter;
    margin-left: -$x-gutter;
    margin-right: -$x-gutter;
  }

  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include breakpoint-media-min($breakpoint) {
      @each $name, $styles in $modal-sizes {
        &.modal-size#{$infix}-#{$name} {
          .modal-dialog {
            @each $key, $value in $styles {
              #{$key}: $value;
            }
          }
        }
      }
      @each $name, $styles in $modal-positions {
        &.modal-position#{$infix}-#{$name} {
          @each $key, $value in $styles {
            #{$key}: $value;
          }
        }
      }
    }
  }
}

html {
  &.backdrop {
    overflow: hidden;
    
    body {
      height: 100%;
      position: relative;
    }
  }

  .cdk-overlay-container {
    z-index: $zindex-dropdown;
  }
}
