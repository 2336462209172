@use "theming/theming";

@mixin color($palette) {
  $accent-strong: theming.get-color($palette, text-primary-800);
  $base-strong: theming.get-color($palette, text-primary-800);
  $background-gradient: theming.get-color($palette, background-gradient);

  $accents-secondary-500: theming.get-color($palette, accents-secondary-500);

  html,
  body {
    background: $background-gradient;
    color: $base-strong;
  }

  .dashed-link {
    color: $accent-strong;
  }

  .link {
    color: $accents-secondary-500;
  }
}
