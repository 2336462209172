@use "sass:map";
@import "../../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-input-with-buttons-v2 {
    wb-ui-validation-error {
      display: block;
      margin-left: map.get($options, default, input, padding-left);
    }

    @include styles(map.get($options, default));

    &.hovered:not(:disabled, .disabled, .focused, .filled) {
      @include styles(map.get($options, hover));
    }

    &.focused {
      @include styles(map.get($options, focus));
    }

    &.filled:not(.focused, .disabled, :disabled),
    &.confirmed:not(.focused, .disabled, :disabled),
    &.ng-valid:not(.focused, .disabled, :disabled, .hovered) {
      @include styles(map.get($options, filled));
    }

    &.ng-touched.ng-invalid:not(.focused) {
      @include styles(map.get($options, invalid));
    }

    &.disabled,
    &[disabled] {
      @include styles(map.get($options, disabled));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == control) {
        @include buildSelectorStyles(".control", $p1)
      } @else if($n1 == suffix) {
        @include buildSelectorStyles(".input-suffix", $p1)
      } @else {
        @include buildSelectorStyles($n1, $p1)
      }
    } @else {
      .wrapper {
        #{$n1}: #{$p1};
      }
    }
  }
}