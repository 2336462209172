@use "sass:list";
@use "sass:map";
@import "theming/palette";
@import "variables/typography";
@import "variables/border";

%text-mock-base {
  border-radius: list.nth($corners, 2);
  @extend .mocked-animation;
}

.mock {
  @each $heading in $headings {
    .h#{list.index($headings, $heading)},
    h#{list.index($headings, $heading)},
    &.h#{list.index($headings, $heading)},
    &h#{list.index($headings, $heading)} {
      @extend %text-mock-base;
    }
  }

  @each $paragraph in $paragraphs {
    .p#{list.index($paragraphs, $paragraph)},
    &.p#{list.index($paragraphs, $paragraph)} {
      @extend %text-mock-base;
    }
  }

  .caption,
  .legal,
  .button1,
  .button2,
  &.caption,
  &.legal,
  &.button1,
  &.button2 {
    @extend %text-mock-base;
  }

  button,
  &button {
    @extend .mocked-animation;
  }

  .icon,
  &.icon {
    @extend .mocked-animation;

    border-radius: 100%;
  }
}

.mocked-animation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: gradientLoading;
  animation-timing-function: linear;
  //@todo set color
  //background: $gray-weak;
  //background: linear-gradient(90.03deg, $gray-weak-darken 13.39%, $gray-weak 24.5%, $gray-weak-darken 35.1%);
  background-size: 1000px 640px;
}

@keyframes gradientLoading {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
