@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-switch-v2 {
    @include styles(map.get($options, default));

    &.hovered:not(:disabled, .disabled, .filled) {
      @include styles(map.get($options, hover));
    }

    &.hovered.filled:not(:disabled, .disabled) {
      @include styles(map.get($options, hover-filled));
    }

    &.filled {
      @include styles(map.get($options, filled));
    }

    &.disabled,
    &[disabled] {
      @include styles(map.get($options, disabled));
    }

    &.disabled.filled,
    &[disabled].filled {
      @include styles(map.get($options, disabled-filled));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == switch) {
        @include buildSelectorStyles(".switch", $p1)
      } @else if($n1 == toggler) {
        @include buildSelectorStyles(".switch-toggler", $p1)
      }
    } @else {
      #{$n1}: $p1;
    }
  }
}