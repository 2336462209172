@use 'sass:map';
@import 'variables/breakpoints';
@import 'variables/spacing';
@import 'utils';

.container,
.container-fluid {
  width: 100%;
  padding-right: $x-gutter;
  padding-left: $x-gutter;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: $mobile) {
    padding-left: $x-gutter-md;
    padding-right: $x-gutter-md;
  }
}

@each $breakpoint, $container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    @extend .container-fluid;
  }
  @include breakpoint-media-min($breakpoint) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }
    $extend-breakpoint: true;
    .container {
      @extend %responsive-container-#{$breakpoint};
    }
    @each $name, $width in $breakpoints {
      @if ($extend-breakpoint) {
        .container#{breakpoint-infix($name)} {
          @extend %responsive-container-#{$breakpoint};
        }
      }
      @if ($breakpoint == $name) {
        $extend-breakpoint: false;
      }
    }
  }
}
