@use "sass:map";
@use "../tab-selector/tab-selector.theme.scss" as tab-selector;
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($breakpoints, $options: ()) {
  wb-ui-tabset-v2 {
    @include styles($breakpoints, map.get($options, default));

    &.backgrounded {
      @include styles($breakpoints, map.get($options, backgrounded));
    }
  }
}

@mixin styles($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == tabset) {
        @include buildSelectorStyles(".tabset", $p1);
      }@else if($n1 == head) {
        @include buildSelectorStyles(".tabset-head", $p1);
      }@else if($n1 == body) {
        .tabset-body {
          @include buildBody($breakpoints, $p1);
        }
      }@else if($n1 == selector) {
        @include tab-selector.theme($p1);
      }
    } @else if $p1 and $p1 != () {
      #{$n1}: $p1;
    }
  }
}

@mixin buildBody($breakpoints, $list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if map.get($breakpoints, $n1) {
        @each $n2, $p2 in $p1 {
          @if($n2 == max) {
            @include breakpoint-media-max($n1) {
              @each $n3, $p3 in $p2 {
                #{$n3}: $p3;
              }
            }
          } @else if($n2 == min) {
            @include breakpoint-media-min($n1) {
              @each $n3, $p3 in $p2 {
                #{$n3}: $p3;
              }
            }
          }
        }

      }
    } @else if $p1 and $p1 != () {
      #{$n1}: $p1;
    }
  }
}