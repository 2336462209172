@use "sass:map";
@use "sass:list";
@use "theming/theming";
@import "variables/shadows";
@import "variables/breakpoints";
@import "utils";

@mixin color($palette) {
  @each $shadow, $color in $shadows {
    @each $level in $shadow-levels {
      .shadowed-#{list.index($shadow-levels, $level)}#{$shadow} {
        box-shadow: list.nth($level, 1) theming.get-color($palette, $color, list.nth($level, 2)),
        list.nth($level, 3) theming.get-color($palette, $color, list.nth($level, 4));
      }
      .shadowed-#{list.index($shadow-levels, $level)}#{$shadow}-hovered:hover {
        box-shadow: list.nth($level, 1) theming.get-color($palette, $color, list.nth($level, 2)),
        list.nth($level, 3) theming.get-color($palette, $color, list.nth($level, 4));
      }
    }
  }

  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include breakpoint-media-min($breakpoint) {
      .shadowed#{$infix}-main {
        box-shadow: -5px 5px 10px rgba(239, 241, 248, .2),
        5px -5px 10px rgba(223, 228, 242, .2),
        5px 5px 15px #DFE4F2;
      }

      .shadowed#{$infix}-header {
        box-shadow: 0px 5px 15px 0px #1D24311A;
      }

      .shadowed#{$infix}-calculator {
        box-shadow: 0px 0px 40px rgba(104, 109, 129, 0.25);
      }
    }
  }
}
