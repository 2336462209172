@import "./variables/breakpoints";

.card {
  min-width: 320px;

  &.loan {
    @media (min-width: $sm) {
      &-common,
      &-active,
      &-overdue,
      &-info,
      &-installment {
        max-width: 400px;
      }

      &-active {
        min-width: 375px;
      }

      &-cancelled,
      &-installment-requested {
        max-width: 528px;
      }

      &-calculator,
      &-signing,
      &-sold {
        max-width: 626px;
      }
    }

    &-calculator {
      & .info-pin {
        // @todo: временно, пока не решим что делать с тенями
        box-shadow: 0px 4px 16px rgba(104, 109, 129, 0.15);

        min-width: 63px;
        height: 68px;
      }
    }
  }

  .card-shadow {
    // @todo: временно, пока не решим что делать с тенями
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), 5px 5px 15px #E6EDF3;
  }
}
