@use "sass:map";
@use "theming/theming";
@import "variables/colors";
@import "utils";

@mixin color($palette) {
  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint);
    @include breakpoint-media-min($breakpoint) {
      @each $color, $value in map.get($bg-colors, responsive) {
        .bg#{$infix}-#{$color} {
          background: theming.get-color($palette, map.get($value, main));
        }
        .bg#{$infix}-#{$color}-responsive {
          @include make-responsive-bg($palette, responsive $color);
        }
      }

      @each $color, $value in map.get($bg-colors, gradient) {
        .bg#{$infix}-#{$color} {
          background: theming.get-color($palette, $value);
        }
      }

      @each $color, $value in map.get($bg-colors, simple) {
        .bg#{$infix}-#{$color} {
          background: theming.get-color($palette, $value);
        }
      }



      @each $color, $value in map.get($text-colors, responsive) {
        .color#{$infix}-#{$color} {
          color: theming.get-color($palette, map.get($value, main));
        }

        .color#{$infix}-#{$color}-responsive {
          @include make-responsive-color($palette, responsive $color);
        }
      }

      @each $color, $value in map.get($text-colors, simple) {
        .color#{$infix}-#{$color} {
          color: theming.get-color($palette, $value);
        }

        .color#{$infix}-#{$color}-hovered:hover {
          color: theming.get-color($palette, $value);
        }
      }

    }
  }}
