@use "sass:map";
@use "sass:string";
@import "../../../../../../assets/styles/utils.scss";

@mixin theme($options: ()) {
  wb-ui-carousel-v2 {
    @include styles(map.get($options, default));
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if ($n1 == container) {
        @include buildSelectorStyles(".carousel-container", $p1);
      } @else if($n1 == content) {
        @include buildSelectorStyles(".carousel-content", $p1);
      } @else if($n1 == nav) {
        @include buildNavigation($p1);
      } @else if($n1 == dots) {
        @include buildDots($p1);
      }
    } @else {
      #{$n1}: $p1;
    }
  }
}

@mixin buildDots($config) {
  @each $name, $value in $config {
    @if $name == "dot" {
      .carousel-dots .dot {
        @include buildDot($value);
      }
    } @else {
      .carousel-dots {
        #{$name}: $value;
      }
    }
  }
}

@mixin buildDot($config) {
  @each $n1, $p1 in $config {
    @if $n1 == "hover" {
      &:hover {
        @include buildDot($p1);
      }
    } @else if $n1 == "active" {
      &.active {
        @include buildDot($p1);
      }
    } @else if $n1 == "default" {
      @include buildDot($p1);
    } @else {
      #{$n1}: $p1;
    }
  }
}

@mixin buildNavigation($config) {
  @each $name, $value in $config {
    @if $name == "button" {
      .carousel-button button {
        @include buildButton($value);
      }
    } @else if $name == "left" {
      @include buildButtonContainer($value, ".carousel-button.left");
    } @else if $name == "right" {
      @include buildButtonContainer($value, ".carousel-button.right");
    } @else {
      .carousel-button {
        #{$name}: $value;
      }
    }
  }
}

@mixin buildButtonContainer($config, $name) {
  @each $n1, $p1 in $config {
    @if type-of($p1) == "map" {
      @include buildButtonContainer($p1, "#{$name}.#{$n1}");
    } @else {
      #{$name} {
        #{$n1}: $p1;
      }
    }
  }
}

@mixin buildButton($config) {
  @each $n1, $p1 in $config {
    @if $n1 == "after" {
      &::after {
        @each $n2, $p2 in $p1 {
          #{$n2}: $p2;
        }
      }
    } @else if $n1 == "hover" {
      &:hover {
        @include buildButton($p1);
      }
    } @else if $n1 == "active" {
      &:active {
        @include buildButton($p1);
      }
    } @else if $n1 == "disabled" {
      &[disabled] {
        @include buildButton($p1);
      }
    } @else if $n1 == "default" {
      @include buildButton($p1);
    } @else {
      #{$n1}: $p1;
    }
  }
}
