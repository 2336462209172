@use "../../../../../assets/styles/theming/theming";

@mixin color($palette) {
  $accent-strong: theming.get-color($palette, accent-strong);
  $base-strong: theming.get-color($palette, base-strong);
  $base-weak: theming.get-color($palette, base-weak);
  $critic-strong-text: theming.get-color($palette, critic-strong-text);
  $gray-strong-lighten: theming.get-color($palette, gray-strong-lighten);
  $success-strong: theming.get-color($palette, success-strong);
  $warning-strong-lighten: theming.get-color($palette, warning-strong-lighten);

  wb-ui-toast-banner {
    %base-weak-color {
      color: $base-weak;

      .toast-btn,
      .toast-link {
        color: $base-weak;
      }
    }

    .toast {
      &-banner {
        &.message-default {
          background: $base-strong;
          color: $base-weak;

          .toast-btn {
            color: $base-weak;
          }

          .toast-link {
            color: $gray-strong-lighten;
          }
        }

        &.message-error {
          background: $critic-strong-text;
          @extend %base-weak-color;
        }

        &.message-warning {
          background: $warning-strong-lighten;

          .toast-link {
            color: $base-strong;
          }
        }

        &.message-success {
          background: $success-strong;
          @extend %base-weak-color;
        }

        &.message-info {
          background: $accent-strong;
          @extend %base-weak-color;
        }
      }

      &-btn {
        background: theming.get-color($palette, base-weak, 0.25);
      }
    }
  }
}
