$displays: [
  (
    font-size: 64px,
    line-height: 72px,
    letter-spacing: 1px,
  )
];

$headings: [
(
  font-size: 40px,
  line-height: 135%,
),
(
  font-size: 32px,
  line-height: 135%,
),
(
  font-size: 28px,
  line-height: 135%,
),
(
  font-size: 24px,
  line-height: 135%,
),
(
  font-size: 20px,
  line-height: 135%,
),
(
  font-size: 16px,
  line-height: 135%,
)
];

$paragraphs: [
  (
    font-size: 14px,
    line-height: 135%
  ),
  (
    font-size: 12px,
    line-height: 135%,
  ),
  (
    font-size: 16px,
    line-height: 135%
  ),
];

$text-alignments: left, center, right, justify;

$weights: (
  light: $light,
  normal: $normal,
  semibold: $semibold,
  bold: $bold,
);
